<template>
<!-- eslint-disable -->
	<div class="login">
		<div style="margin-top: 150px;">
			<div
				class="mx-auto my-8"
				max-width="330"
				height="350"
			>
				<img class="logo" src="../assets/Logo.svg" alt="" width="72" height="72">
				<h2 class="text-center pt-8">Mascle Memory</h2>
				<v-form @submit.prevent="login">
					<v-text-field class="mt-4" label="ID" outlined dense v-model="userData.userId"></v-text-field>
					<v-text-field label="Password" outlined dense v-model="password" type="password" style="margin-top: -15px;"></v-text-field>
					<v-btn class="mt-4" type="submit" color="primary">Login</v-btn>
				</v-form>
			</div>
			<v-btn
				color="black"
				outlined
				@click="register()"
				style="position: absolute; right: 10%; bottom: 20%;"
			>
				SIGN UP
			</v-btn>
				<!-- <Footer/> -->
		</div>

		<v-dialog
			v-model="popFlg"
			persistent
			max-width="90%"
			>
			<v-card class="gradation">
				<div class="text-center pt-6 pb-4 pl-2" style="white-space: pre-wrap;">
					<h3 v-if="updateMes" class="pb-2">お知らせ</h3>
					{{ this.message }}
				</div>
				<v-card-actions>
					<v-btn block tile color="red" dark depressed small @click="togglePop()" class="mb-2">
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
// import Footer from '@/components/Footer.vue'
export default {
	name: 'Login',
	// components: { Footer },
	data () {
		return {
			userId: '', // 画面側起動用

			loader: null,
			loading: false,
			// ダイアログ
			popFlg: false,
			errorFlg: false,
			password: '',
			message: '',
			userData: {
				userId: '',
				userName: '',
			},

			// アップデート情報
			updateMes: '',
		}
	},
	methods: {
		load() {
			this.userData.userId = '',
			this.password = ''
		},


		login() {
			this.loader = 'loading'
			api.login.login({userId: this.userData.userId, password: this.password}).then((res) => {
				console.log(res)
                switch(res.status) {
                    case 200:
						this.userData = res.data
						this.$store.dispatch('setLoginInfo', this.userData)
						this.goHome();
                        break
                    case 500:
						this.errorFlg = true
						this.pop(res.data.error[0])
                        break
                }
			}).catch(error => {
				// エラーメッセージが存在する場合は表示する
				if (error.response && error.response.data) {
					this.errorFlg = true
					console.log(error);
					console.log('エラーメッセージ: ', error.response.data.message);
					this.pop(error.response.data.message);
				}
			}).finally()
		},



		register() {
			this.$router.push({name: 'Register'})
		},
		pop(message) {
			this.popFlg = true // childの値を書き換え
			this.message = message // childの値を書き換え
		},
        togglePop() {
            this.popFlg = !this.popFlg
			if (this.errorFlg) this.$router.go({path: this.$router.currentRoute.path, force: true})
			if(!this.errorFlg) this.goHome()
        },
		goHome() {
			this.$router.push({ name: 'Home', params: { userData: this.userData } })
		},
	},
	created(){
		this.load()
	},
    watch: {
		loader () {
			const l = this.loader
			this[l] = !this[l]

			setTimeout(() => (this[l] = false), 1000)

			this.loader = null
		},
    },
}
</script>
<style scoped>
body{
	color: black;
}
input:focus {
	border: 2px solid #5EADE4 !important;
	outline: none;
}
.v-form{
	width: 300px;
	margin: 0 auto;
}
.v-btn, .logo{
	display: block;
	margin: auto;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
</style>